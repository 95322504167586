<template>
  <div>
    <WelcomeMessage/>
    <div v-if="dataReceived" class="referral-wrap">
      <div class="header sticky-header">
        <MenuAndLogo></MenuAndLogo>
        <div class="page-title has-text-centered has-text-weight-semibold">Refer your friends</div>
        <div class="share-icon has-background-white is-flex is-justify-content-center is-align-items-center" v-if="webShareApiSupported" @click="doWebShare">
          <img src="../../assets/icons/share.svg" alt="share-icon">
        </div>
      </div>
      <Rewards :rewardList="rewardList" :referred="referred" :remaining="remaining" ref="rewards"></Rewards>
      <SharedLink :referralLink="referralLink" :doWebShare="doWebShare"></SharedLink>
      <!-- <EmailInvite :firstName="firstName" :referralLink="referralLink"></EmailInvite> -->
      <ReferralHistory></ReferralHistory>
    </div>
    <Skeleton v-else/>
  </div>
</template>
<script>
import WelcomeMessage from '@components/desktop/header-welcome-message.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import Rewards from './Rewards.vue'
import SharedLink from './SharedLink.vue'
// import EmailInvite from './EmailInvite.vue'
import ReferralHistory from './ReferralHistory.vue'
import Skeleton from './Skeleton.vue'
import { getReferralDetails } from '@api/referral'
import webShareReferralLink from '@utils/mixins/web-share-referral-link'

export default {
  mixins: [webShareReferralLink],
  data() {
    return {
      dataReceived: false,
      rewardList: [],
      referred: 0,
      remaining: 0,
      addyHandle: '',
      firstName: '',
    }
  },
  components: {
    WelcomeMessage,
    MenuAndLogo,
    Rewards,
    SharedLink,
    // EmailInvite,
    ReferralHistory,
    Skeleton,
  },
  computed: {
    webShareApiSupported() {
      return navigator.share
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      getReferralDetails().then((data) => {
        if (!data.success) {
          this.dataReceived = true
          return
        }
        const { prizes, referred, referralsLeftToNextPrize, handle, firstName } = data.data
        this.rewardList = prizes
        this.referred = referred
        this.remaining = referralsLeftToNextPrize
        this.addyHandle = handle
        this.firstName = firstName

        this.render()
      })
    },
    render() {
      setTimeout(() => {
        this.dataReceived = true
        this.$nextTick(() => {
          this.$refs.rewards.initialize()
        })
      }, 500)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.referral-wrap {
  padding-top: 118px;
  padding-bottom: 160px;
  background-color: #fafafa;
  .header {
    width: 100vw;
    height: 118px;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    border-radius: 0px 0px 24px 24px;
    background-color: #F0EEF8;
    .share-icon {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      position: absolute;
      top: 14px;
      right: 25px;
      > img {
        width: 12px;
      }
    }
  }
  .page-title {
    font-size: 24px;
    letter-spacing: 0.03em;
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .referral-wrap {
    padding-top: 30px;
    background-color: transparent;
  }
}
</style>
