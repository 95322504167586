<template>
  <div class="referral-history-wrap">
    <div class="section-title has-text-weight-semibold">Track your network</div>
    <div class="section-subtitle">Keep tabs on who you’ve referred!</div>
    <div class="list confirmed has-background-white">
      <div class="list-title has-text-weight-semibold">Confirmed referrals</div>
      <div v-if="confirmedList && confirmedList.length">
        <div class="invite has-text-blue" v-for="(name, index) in confirmedList" :key="index">{{name}}</div>
      </div>
      <div v-else class="placeholder">Your friends haven’t become members yet!</div>
    </div>
    <!-- <div class="list pending has-background-white">
      <div class="list-title has-text-weight-semibold">Email invites sent</div>
      <div v-if="pendingList && pendingList.length">
        <div class="invite has-text-blue" v-for="(email, index) in pendingList" :key="index">{{email}}</div>
      </div>
      <div v-else class="placeholder">You haven’t referred anyone yet!</div>
    </div> -->
  </div>
</template>
<script>
import { getReferralLists } from '@api/referral'

export default {
  data() {
    return {
      confirmedList: [],
      pendingList: [],
    }
  },
  created() {
    this.getLists()
  },
  methods: {
    getLists() {
      getReferralLists().then((data) => {
        if (!data.success) return
        const { confirmed, invited } = data.data
        this.confirmedList = confirmed
        this.pendingList = invited
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.referral-history-wrap {
  padding: 60px 25px;
  .section-title {
    font-size: 24px;
    padding-bottom: 6px;
  }
  .section-subtitle {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 4px;
  }
  .list {
    border-radius: 12px;
    padding: 16px;
    margin-top: 20px;
    .list-title {
      font-size: 16px;
    }
    .invite {
      font-size: 16px;
      line-height: 19px;
      margin-top: 8px;
    }
    .placeholder {
      color: #9185AE;
      font-size: 16px;
      line-height: 19px;
      padding: 12px 0 4px;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .list {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
}
</style>
