<template>
  <div class="shared-link">
    <div class="section-title has-text-weight-semibold">Share your link</div>
    <div class="section-subtitle">Rack up referrals by sharing your personal referral link with others:</div>
    <div :class="`dash-box is-clickable ${linkCopied ? 'link-copied' : ''}`" @click="copyLink">
      <div class="referral-link has-text-blue has-text-weight-semibold">{{referralLink}}</div>
      <div class="text-of-shared-link has-text-centered">{{textOfSharedLink}}</div>
    </div>
    <div class="has-text-centered" v-if="webShareApiSupported" @click="doWebShare">
      <b-button type="is-blue button-share" class="has-text-weight-bold is-medium-height">Share link</b-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      linkCopied: false,
    }
  },
  props: {
    referralLink: {
      type: String,
      required: true
    },
    doWebShare: {
      type: Function,
      required: true
    }
  },
  computed: {
    textOfSharedLink() {
      return this.linkCopied ? 'Copied!' : 'Tap to copy!'
    },
    webShareApiSupported() {
      return navigator.share
    },
  },
  methods: {
    copyLink() {
      let delay = 0
      if (this.linkCopied) {
        this.linkCopied = false
        delay = 500
      }
      setTimeout(() => {
        this.$copyText(this.referralLink).then(() => {
          this.linkCopied = true
        })
      }, delay)
    },
  },
}
</script>
<style lang="scss" scoped>
.shared-link {
  padding: 16px 25px;
  .section-title {
    font-size: 24px;
    padding-bottom: 6px;
  }
  .section-subtitle {
    font-size: 16px;
    line-height: 19px;
  }
  .dash-box {
    width: 269px;
    height: 62px;
    background: url('../../assets/images/referral/dash-outline.png') center/contain no-repeat;
    border-radius: 12px;
    margin: 22px auto 14px;
    padding: 10px 20px 0;
    &.link-copied {
      background-color: #F0EEF8;
    }
    .referral-link {
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .text-of-shared-link {
      margin-top: 8px;
      color: #9185AE;
      font-size: 14px;
    }
  }
  .button-share {
    width: 270px;
    border-radius: 12px;
  }
}
</style>
