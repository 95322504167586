<template>
  <div class="skeleton-referral-page loading-skeleton">
    <div class="header sticky-header">
      <MenuAndLogo></MenuAndLogo>
      <div class="mt-1">
        <b-skeleton size="is-medium" width="50%" position="is-centered"></b-skeleton>
      </div>
    </div>
    <div class="main">
      <b-skeleton width="30%" position="is-left"></b-skeleton>
      <b-skeleton position="is-left"></b-skeleton>
      <div class="swiper mt-5">
        <div class="swiper-item is-inline-block"></div>
        <div class="swiper-item is-inline-block"></div>
      </div>
      <div class="is-flex overview is-justify-content-space-between mb-6">
        <div class="overview-item"></div>
        <div class="overview-item"></div>
      </div>
      <b-skeleton width="30%" position="is-left"></b-skeleton>
      <b-skeleton position="is-left"></b-skeleton>
      <div class="share-link has-text-centered mt-5 mb-6">
        <span class="is-inline-block has-background-white"></span>
      </div>
      <b-skeleton width="30%" position="is-left"></b-skeleton>
      <b-skeleton position="is-left"></b-skeleton>
      <div class="email has-text-centered mt-5 mb-6">
        <span class="is-inline-block has-background-white"></span>
      </div>
      <b-skeleton width="30%" position="is-left"></b-skeleton>
      <b-skeleton position="is-left"></b-skeleton>
      <div class="track has-text-centered mt-5">
        <span class="is-inline-block first has-background-white"></span>
        <span class="is-inline-block second has-background-white"></span>
      </div>
    </div>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'

export default {
  components: {
    MenuAndLogo,
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.skeleton-referral-page {
  min-height: 100vh;
  background: #fafafa;
  padding-top: 118px;
  overflow: hidden;
  .header {
    width: 100vw;
    height: 118px;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    border-radius: 0px 0px 24px 24px;
    background-color: #F0EEF8;
  }
  .main {
    padding: 25px;
    .swiper {
      white-space: nowrap;
      .swiper-item {
        width: 214px;
        height: 272px;
        margin-right: 12px;
        background: #f0eef8;
        border-radius: 0.75rem;
      }
    }
    .overview {
      margin-top: 16px;
      .overview-item {
        width: 157px;
        height: 97px;
        border-radius: 12px;
        box-shadow: 0px 6px 30px rgba(215, 145, 51, 0.1);
      }
    }
    .share-link {
      span {
        width: 269px;
        height: 62px;
        border-radius: 12px;
      }
    }
    .email {
      span {
        width: 80%;
        height: 300px;
        border-radius: 12px;
      }
    }
    .track {
      span {
        width: 100%;
        border-radius: 12px;
        &.first {
          height: 129px;
        }
        &.second {
          height: 83px;
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .skeleton-referral-page {
    padding-top: 30px;
    background-color: transparent;
  }
}
</style>
