<template>
  <div class="rewards">
    <div class="section-title has-text-weight-semibold">Your rewards</div>
    <div class="section-subtitle">Share addy and earn rewards when they become a member! 🎁</div>
    <div class="swiper-box">
      <div class="rewards-swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="reward in rewardList"
            :key="reward.id"
            class="swiper-slide"
          >
            <div class="reward-index has-text-blue has-text-weight-semibold">{{reward.name}}</div>
            <div class="reward-image" :style="`background: url(${reward.imageUrl}) center/contain no-repeat;`"></div>
            <div class="minimum-referrals">{{reward.minimumRef}} {{reward.minimumRef | pluralize('referral')}}</div>
            <div class="reward-description">{{reward.description}}</div>
          </div>
        </div>
        <div class="rewards-swiper-navigation" v-if="!isMobile">
          <div class="swiper-button-prev" @click="slidePrev"></div>
          <div class="swiper-button-next" @click="slideNext"></div>
        </div>
      </div>
    </div>
    <div class="num is-flex is-justify-content-space-between">
      <div class="referred is-relative">
        <div class="hidden"></div>
        <div class="has-text-centered has-background-white has-content" @click="toTrackNetwork">
          <div class="card-title has-text-weight-semibold">Members referred</div>
          <div class="card-num has-text-blue" data-cy="members-referred">{{referred}}</div>
        </div>
      </div>
      <div class="remaining is-relative">
         <div class="hidden"></div>
        <div class="has-text-centered has-background-white has-content">
          <div class="card-title has-text-weight-semibold">Until next reward</div>
          <div class="card-num has-text-blue">{{remaining}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import { mobileCheck } from '@utils/common-methods/common'

export default {
  data() {
    return {
      rewardsSwiper: undefined,
    }
  },
  props: {
    rewardList: {
      type: Array,
      required: true
    },
    referred: {
      type: Number,
      required: true
    },
    remaining: {
      type: Number,
      required: true
    },
  },
  filters: {
    pluralize: function(amount, singular, plural = `${singular}s`) {
      return parseInt(amount) === 1 ? singular : plural
    },
  },
  computed: {
    isMobile() {
      return mobileCheck()
    },
  },
  methods: {
    initialize() {
      /* eslint-disable no-new */
      this.rewardsSwiper = new Swiper('.rewards-swiper-container', {
        initialSlide: 0,
        slidesPerView: 1.6,
        spaceBetween: 12,
        loop: true,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
    },
    toTrackNetwork() {
      document.getElementById('anchor-for-referral-history').scrollIntoView()
    },
    slidePrev() {
      this.rewardsSwiper.slidePrev()
    },
    slideNext() {
      this.rewardsSwiper.slideNext()
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

  .rewards {
    padding: 25px 0 25px 25px;
    .section-title {
      font-size: 24px;
      padding-bottom: 6px;
    }
    .section-subtitle {
      padding-right: 25px;
      font-size: 16px;
      line-height: 19px;
    }
    .swiper-box {
      margin-top: 16px;
      .rewards-swiper-container {
        position: relative;
        overflow: hidden;
        .swiper-slide {
          height: 272px;
          background: #F0EEF8;
          border-radius: 12px;
          padding: 14px 16px;
          .reward-index {
            font-size: 14px;
          }
          .reward-image {
            height: 124px;
            margin-top: 16px;
          }
          .minimum-referrals {
            width: 100%;
            position: absolute;
            color: #9185AE;
            font-size: 16px;
            bottom: 12px;
            left: 0;
            padding-left: 16px;
          }
          .reward-description {
            padding: 0 16px;
            font-size: 14px;
            line-height: 17px;
            position: absolute;
            width: 100%;
            bottom: 39px;
            left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          opacity: 0.3;
          color: #4A26AA !important;
          transform: translateY(-50%);
          &:hover {
            background: #f6f5fc86;
            opacity: 1;
          }
        }
      }
    }
    .num {
      margin-top: 16px;
      padding-right: 25px;
      > div {
        width: calc(50% - 6px);
        height: 97px;
        .hidden {
          position: absolute;
          z-index: 1;
          width: 149px;
          height: 38px;
          border-radius: 12px;
          box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
          bottom: 0;
          left: 4px;
        }
        .has-content {
          position: absolute;
          width: 100%;
          height: 100%;
          padding-top: 16px;
          z-index: 2;
          border-radius: 12px;
        }
        .card-title {
          font-size: 16px;
        }
        .card-num {
          padding-top: 10px;
          font-size: 32px;
        }
      }
    }
  }

@media only screen and (min-width: $min-viewport-width) {
  .num .hidden {
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.25);
  }
}
</style>
